const env = () => {
  return {
    CONFIG_BUILD_ID: process.env.CONFIG_BUILD_ID,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_DEFAULT_LOCALE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    DB_HOST: process.env.DB_HOST,
    DB_PORT: process.env.DB_PORT,
    DB_USER: process.env.DB_USER,
    DB_PASSWORD: process.env.DB_PASSWORD,
    DB_SSLMODE: process.env.DB_SSLMODE,
    DB_DATABASE: process.env.DB_DATABASE,
    DB_SHOW_LOG: process.env.DB_SHOW_LOG,
    ALLOWED_ORIGINS: process.env.ALLOWED_ORIGINS,
    HASH_SALT_ROUNDS: process.env.HASH_SALT_ROUNDS,
    AUTH_FORM_COOKIE_PRIVATE_KEY: process.env.AUTH_FORM_COOKIE_PRIVATE_KEY,
    AUTH_FORM_REFRESH_COOKIE_EXPIRES_IN: process.env.AUTH_FORM_REFRESH_COOKIE_EXPIRES_IN,
    AUTH_FORM_RESET_PASSWORD_CODE_EXPIRES_IN: process.env.AUTH_FORM_RESET_PASSWORD_CODE_EXPIRES_IN,
    TOKEN_PRIVATE_KEY: process.env.TOKEN_PRIVATE_KEY,
    TOKEN_PUBLIC_KEY: process.env.TOKEN_PUBLIC_KEY,
    ACCESS_TOKENS_EXPIRES_IN: process.env.ACCESS_TOKENS_EXPIRES_IN,
    REFRESH_TOKEN_EXPIRES_IN: process.env.REFRESH_TOKEN_EXPIRES_IN,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_PROJECT: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
    NEXT_PUBLIC_CONTACT_EMAIL: process.env.NEXT_PUBLIC_CONTACT_EMAIL,
    NEXT_PUBLIC_TERMS_CONDITIONS_URL: process.env.NEXT_PUBLIC_TERMS_CONDITIONS_URL,
    REQUIRED_MACHINE_TOKEN: process.env.REQUIRED_MACHINE_TOKEN,
    NEXT_PUBLIC_API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN,
    DEFAULT_CLIENT_ID: process.env.DEFAULT_CLIENT_ID || '',
    PROFILE_CLIENT_ID: process.env.PROFILE_CLIENT_ID,
    NEXT_PUBLIC_CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID || 'export-connect-auth'
  };
};

export default env;
